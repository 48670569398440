import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
// import { Navbar, Nav, NavDropdown } from 'react-bootstrap';
import logoLeft from "../Images/1615631850niva4logo.png";
import Sidebar from "../Components/TopupButtons/Sidebar";
import "./Samp.css";
import axios from "axios";
import { ngrokUrl } from "./data";

function NavBar() {
  const [faviconData, setFaviconData] = useState({});
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const location = useLocation();
  const currentPath = location.pathname;

  const getLinkStyle = (path) => {
    const isPortfolio =
      path === "/portfolio" &&
      (currentPath === "/portfolio" || currentPath.startsWith("/project/"));
    const isBlog = path === "/blog" && currentPath.startsWith("/post/");
    const isActive = currentPath === path || isPortfolio || isBlog;

    return isActive ? { color: "#94618e" } : {};
  };

  useEffect(() => {
    const fetchDefaultValues = async () => {
      setLoading(true);
      try {
        const response = await axios.get(`${ngrokUrl.url}/api/readLogo/1`);
        setFaviconData(response.data);
        setLoading(false);
      } catch (error) {
        console.error("Failed to fetch default values.", error);
        setError("Failed to fetch default values.");
        setLoading(false);
      }
    };

    fetchDefaultValues();
  }, []);

  return (
    <div className="sti">
      <header className="header-niva">
        <nav className="navbar navbar-expand-lg ">
          <div className="container">
            <div className="row w-100">
              <div className="navbar-header col-6 col-md-2">
                <a className="navbar-brand" title="Niva CMS Theme">
                  <Link to="/">
                    <img
                      style={{ marginTop: "-15px" }}
                      className="img-fluid logo-front"
                      // src={faviconData.main_logo_url}
                      src={logoLeft}
                      alt=""
                    />
                  </Link>
                </a>
              </div>

              <div className="navbar-menu  col-md-8">
                <button
                  className="navbar-toggler"
                  // type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#navbarNiva"
                  aria-controls="navbarNiva"
                  aria-expanded="false"
                  aria-label="Toggle navigation"
                >
                  <i className="fas fa-bars "></i>
                </button>

                <div
                  className="collapse navbar-collapse not_collapsing"
                  id="navbarNiva"
                >
                  <ul className="navbar-nav mr-auto ml-auto">
                    <li className="nav-item">
                      <Link
                        title="Home"
                        className="nav-link"
                        to="/"
                        style={getLinkStyle("/")}
                      >
                        Home
                      </Link>{" "}
                    </li>

                    <li className="nav-item">
                      {" "}
                      <Link
                        to="/about-us"
                        title="About us"
                        className="nav-link"
                        style={{
                          ...getLinkStyle("/about-us"),
                          whiteSpace: "nowrap",
                        }}
                      >
                        About us
                      </Link>{" "}
                    </li>

                    <li className="nav-item">
                      <Link
                        title="Industries"
                        className="nav-link"
                        to="/industries"
                        style={getLinkStyle("/industries")}
                      >
                        Industries
                      </Link>{" "}
                    </li>

                    <li className="nav-item">
                      <Link
                        title="Services"
                        className="nav-link"
                        to="/ourServices"
                        style={getLinkStyle("/ourServices")}
                      >
                        Services
                      </Link>{" "}
                    </li>

                    {/* <li className="nav-item dropdown">
                      <a
                        title="Portfolio"
                        className="nav-link "
                        role="button"
                        aria-haspopup="true"
                        aria-expanded="false"
                        href="/portfolio"
                        style={getLinkStyle("/portfolio")}
                      >
                        Portfolio
                      </a>
                    </li> */}

                    <li className="nav-item dropdown">
                      <a
                        title="blog"
                        className="nav-link "
                        role="button"
                        href="/blog"
                        aria-haspopup="true"
                        aria-expanded="false"
                        style={getLinkStyle("/blog")}
                      >
                        Blog
                      </a>
                    </li>

                    <li className="nav-item">
                      <Link
                        title="Contact"
                        className="nav-link"
                        to="/contact"
                        style={getLinkStyle("/contact")}
                      >
                        Contact
                      </Link>{" "}
                    </li>
                  </ul>
                </div>
              </div>

              <div className="navbar-buttons  col-6  col-md-2">
                <div className="navbar-buttons-inner">
                  <div className="link_user_section">
                    <div className="link_user_section_inner">
                      {/* <Link to="/login">
                        <i className="far fa-user"></i>
                      </Link> */}
                    </div>
                  </div>

                  <div className="side_panel_sidebar_parent">
                    <Sidebar />
                    {/* <div className="side_panel_sidebar">
                                        <span className="side_panel_toggle">
                                            <span></span>
                                            <span></span>
                                            <span></span>
                                            <span></span>
                                        </span>
                                    </div> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </nav>
      </header>
    </div>
  );
}

export default NavBar;
