import React from "react";
import { Routes, Route, useLocation } from "react-router-dom";
import Home from "../Pages/Home";
import AboutUs from "../Pages/AboutUs";
import Contact from "../Pages/Contact";
import Portfolio from "../Pages/Portfolio";
import Pricing from "../Pages/Pricing";
import Blog from "../Pages/Blog";
import NivaWordpressTheme from "../Components/Portfolio/NivaWordpressTheme";
import VenorWordpress from "../Components/Portfolio/VenorWordpress";
import Blog6Articles from "../Components/Blog/Blog6Articles";
import Top7articles from "../Components/Blog/Top7articles";
import NavBar from "./NavBar";
import ProjectNivaCms from "../Components/Portfolio/ProjectNivaCms";
import ProjectRentZone from "../Components/Portfolio/ProjectRentZone";
import ProjectDigma from "../Components/Portfolio/ProjectDigma";
import ProjectAltem from "../Components/Portfolio/ProjectAltem";
import BlogDesigner from "../Components/Blog/BlogDesigner";
import Gdpr from "../Components/Contact/Gdpr";
import TermsAndConditions from "../Components/Contact/TermsAndConditions";
import PrivacyPolicy from "../Components/Contact/PrivacyPolicy";
import Login from "../Authentication/Login";
import Dashboard from "../Components/DashBoard/Dashboard";
import DashboardStats from "../Components/DashBoard/DashboardStats";
import AllPages from "../Components/Pages/AllPages";
import CreatePage from "../Components/Pages/CreatePage";
import CustomTemplate from "../Components/Pages/CustomTemplate";
import AllProject from "../Components/Projects/AllProject";
import CreateProject from "../Components/Projects/CreateProject";
import AllCategories from "../Components/Projects/AllCategories";
import AllPosts from "../Components/Posts/AllPosts";
import CreatePost from "../Components/Posts/CreatePost";
import PostCategories from "../Components/Posts/PostCategories";
import Upload from "../Components/Media/Upload";
import CreateUser from "../Components/Users/CreateUser";
import ManageSlider from "../Components/Elements/ManageSlider";
import ManageServices from "../Components/Elements/ManageServices";
import ManageTestimonials from "../Components/Elements/ManageTestimonials";
import ManageClients from "../Components/Elements/ManageClients";
import ManagePricing from "../Components/Elements/ManagePricing";
import WebsiteSettting from "../Components/Settings/WebsiteSettting";
import WebsiteNavigation from "../Components/Settings/WebsiteNavigation";
import WebisteHeaderFooter from "../Components/Settings/WebisteHeaderFooter";
import WebsiteLanguage from "../Components/Settings/WebsiteLanguage";
import CreateLanguage from "../Components/Settings/CreateLanguage";
import CreateMenu from "../Components/Settings/CreateMenu";
import HomePage from "../Components/Projects/HomePage";
import ProjectPage from "../Components/Projects/ProjectPage";
import CreateSlider from "../Components/Elements/CreateSlider";
import CreateServices from "../Components/Elements/CreateServices";
import CreateTestimonials from "../Components/Elements/CreateTestimonials";
import CreateClient from "../Components/Elements/CreateClient";
import ContactSettings from "../Components/Elements/ContactSettings";
import AboutSettings from "../Components/Elements/AboutSettings";
import PricingPage from "../Components/Elements/PricingPage";
import CreatePricing from "../Components/Elements/CreatePricing";
import BlogSetting from "../Components/Posts/BlogSetting";
import AllMedia from "../Components/Media/AllMedia";
import EditUser from "../Components/Users/EditUser";
import GoogleAnalytics from "../Components/Settings/GoogleAnalytics";
import WebsiteTracking from "../Components/Settings/WebsiteTracking";
import GoogleId from "../Components/Settings/GoogleId";
import GoogleCode from "../Components/Settings/GoogleCode";
import LogoSeo from "../Components/Settings/LogoSeo";
import Favicon from "../Components/Settings/Favicon";
import Meta from "../Components/Settings/Meta";
import Industries from "../Components/Industries/Industries";
import Captcha from "../Components/Settings/Captcha";
import OurServices from "../Pages/OurServices";
import ContactPageMap from "../Components/Contact/ContactPageMap";
import GDPRSettings from "../Components/Settings/GDPRSettings";
import EditPage from "../Components/Pages/EditPage/EditPage";
import DynamicRoutes from "../Components/Pages/DynamicRoutes";
import Editproject from "../Components/Projects/EditprojectPage/Editproject";
import EditAllCategories from "../Components/Projects/EditprojectPage/EditAllCategories";
import Editpost from "../Components/Posts/Editposts/Editpost";
import EditPostCategories from "../Components/Posts/Editposts/EditPostCategories";
import EditSlider from "../Components/Elements/EditElements/EditSlider";
import EditService from "../Components/Elements/EditElements/EditService";
import EditTestimonial from "../Components/Elements/EditElements/EditTestimonial";
import EditClient from "../Components/Elements/EditElements/EditClient";
import EditPricing from "../Components/Elements/EditElements/EditPricing";
import NavigationDynamicRoutes from "../Components/Settings/EditSettings/NavigationDynamicRoutes";
import AllUsers from "../Components/Users/AllUsers";
import AllMembers from "../Components/Members/AllMembers";
import CreateMembers from "../Components/Members/CreateMembers";
import EditMembers from "../Components/Members/EditMembers";


function RouterComponent() {
  const location = useLocation();
  return (
    <>
      {/* {location.pathname !== "/login" && <NavBar />} */}
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/login" element={<Login />} />
        <Route path="/about-us" element={<AboutUs />} />
        <Route path="/portfolio" element={<Portfolio />} />
        <Route path="/pricing" element={<Pricing />} />
        <Route path="/blog" element={<Blog />} />
        {/* <Route path="/contact" element={< />} /> */}
        <Route path="/industries" element={<Industries />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/ourServices" element={<OurServices />} />
        <Route path="/project/niva" element={<NivaWordpressTheme />} />
        <Route path="/project/venor" element={<VenorWordpress />} />
        <Route
          path="/post/top-6-articles-you-must-read-today-niva"
          element={<Blog6Articles />}
        />
        <Route
          path="/post/7-creative-ways-to-boost-your-social-media"
          element={<Top7articles />}
        />
        <Route path="/project/niva-cms" element={<ProjectNivaCms />} />
        <Route path="/project/rentzone" element={<ProjectRentZone />} />
        <Route path="/project/digma" element={<ProjectDigma />} />
        <Route path="/project/altem" element={<ProjectAltem />} />
        <Route
          path="/post/:id"
          element={<BlogDesigner />}
        />
        
        <Route path="/ContactPageMap" element={<ContactPageMap />} />

        {/* footer */}

        <Route path="/gdpr" element={<Gdpr />} />
        <Route path="/terms-conditions" element={<TermsAndConditions />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />

        {/* Dashboard Routes */}
        <Route path="/dashboard" element={<Dashboard />}>
          <Route index element={<DashboardStats />} />
          {/* <Route path="editUser" element={<EditUser />} /> */}
          <Route path="all-pages" element={<AllPages />} />
          <Route path="createPage" element={<CreatePage />} />
          <Route path="customTemplate" element={<CustomTemplate />} />
          <Route path="all-project" element={<AllProject />} />
          <Route path="createProject" element={<CreateProject />} />
          <Route path="allCategories" element={<AllCategories />} />
          <Route path="homepage" element={<HomePage />} />
          <Route path="projectpage" element={<ProjectPage />} />
          <Route path="allPosts" element={<AllPosts />} />
          <Route path="blogSetting" element={<BlogSetting />} />
          <Route path="createPost" element={<CreatePost />} />
          <Route path="postCategory" element={<PostCategories />} />
          <Route path="allMedia" element={<AllMedia />} />
          <Route path="upload" element={<Upload />} />
          <Route path="allUsers" element={<AllUsers />} />
          <Route path="createUsers" element={<CreateUser />} />
          <Route path="members" element={<AllMembers />} />
          <Route path="createMember" element={<CreateMembers />} />

          <Route path="manageslider" element={<ManageSlider />} />
          <Route path="createSlider" element={<CreateSlider />} />
          <Route path="manageservices" element={<ManageServices />} />
          <Route path="createServices" element={<CreateServices />} />
          <Route path="managetestimonials" element={<ManageTestimonials />} />
          <Route path="createTestimonials" element={<CreateTestimonials />} />
          <Route path="manageclients" element={<ManageClients />} />
          <Route path="createClient" element={<CreateClient />} />
          <Route path="contactSetting" element={<ContactSettings />} />
          <Route path="aboutSetting" element={<AboutSettings />} />
          <Route path="googleAnalytics" element={<GoogleAnalytics />} />
          <Route path="websiteTracking" element={<WebsiteTracking />}>
            <Route index element={<GoogleId />} />
            <Route path="googleCode" element={<GoogleCode />} />
          </Route>
          <Route path="managepricing" element={<ManagePricing />} />
          <Route path="pricingPage" element={<PricingPage />} />
          <Route path="createPricing" element={<CreatePricing />} />
          <Route path="websiteSetting" element={<WebsiteSettting />} />
          <Route path="logoSeo" element={<LogoSeo />}>
            <Route index element={<Favicon />} />
            <Route path="meta" element={<Meta />} />
          </Route>
          <Route path="websitenavigation" element={<WebsiteNavigation />} />
          <Route path="websitefooter" element={<WebisteHeaderFooter />} />
          <Route path="websitelanguages" element={<WebsiteLanguage />} />
          <Route path="createlanguage" element={<CreateLanguage />} />
          <Route path="createmenu" element={<CreateMenu />} />
          <Route path="captcha" element={<Captcha />} />
          <Route path="gdprSettings" element={<GDPRSettings />} />
          <Route path="page/:id/edit" element={<EditPage />} />
          <Route path="customtemplate/:id/edit" element={<DynamicRoutes />} />
          <Route path="editproject/:id/edit" element={<Editproject />} />
          <Route
            path="projectcategory/:id/edit"
            element={<EditAllCategories />}
          />
          <Route path="editPost/:id/edit" element={<Editpost />} />
          <Route
            path="postCategoriesEdit/:id/edit"
            element={<EditPostCategories />}
          />
          <Route path="editslider/:id/edit" element={<EditSlider />} />
          <Route path="editServices/:id/edit" element={<EditService />} />
          <Route
            path="editTestimonials/:id/edit"
            element={<EditTestimonial />}
          />
          <Route path="editClients/:id/edit" element={<EditClient />} />
          <Route path="editPricing/:id/edit" element={<EditPricing />} />
          <Route
            path="editNavigation/:id/edit"
            element={<NavigationDynamicRoutes />}
          />
          <Route path="EditUsers/:id/edit" element={<EditUser />} />
          <Route path="Editmember/:id/edit" element={<EditMembers />} />
        </Route>
      </Routes>
    </>
  );
}
export default RouterComponent;
