import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { ngrokUrl } from "../../Utility/data";
import Loader from "../Loader/Loader";

function ContactSection() {
  const [information, setInformation] = useState({});
  const [loadinginfo, setLoadinginfo] = useState(false);
  const [errorinfo, setErrorinfo] = useState(null);
  const navigate = useNavigate();

  const details = information.box_desc_one
    ? information.box_desc_one.split(/[,\n]/).map((detail) => detail.trim())
    : [];
  const emails = information.box_desc_two
    ? information.box_desc_two.split(/[,\n]/).map((email) => email.trim())
    : [];
  const address = information.box_desc_three
    ? information.box_desc_three.split("\n")
    : [];

  useEffect(() => {
    const contactInformation = async () => {
      setLoadinginfo(true);
      try {
        const response = await axios.get(`${ngrokUrl.url}/api/readContactUs/1`);
        setInformation(response.data);
        setLoadinginfo(false);
      } catch (error) {
        console.error("Failed to fetch default values.", error);
        setErrorinfo("Failed to fetch default values.");
        setLoadinginfo(false);
      }
    };
    contactInformation();
  }, []);

  return (
    <div>
      <div className="breadcrumb-area">
        <h1 className="breadcrumb-title">Contact us</h1>
        <ul className="page-list">
          <li className="item-home">
            <a
              className="bread-link"
              onClick={() => navigate("/")}
              title="Home"
            >
              Home
            </a>
          </li>
          <li className="separator separator-home"></li>
          <li className="item-current">Contact us</li>
        </ul>
      </div>

      {loadinginfo ? (
        <Loader />
      ) : (
        <div
          className="contant-section-page portfolio-section-filters"
        
        >
          <div className="container" style={{minHeight:"10vh"}}>
            <div className="row">
              <div className="col-md-4">
                <div
                  className="contact-element-wrapper"
                  style={{ height: "225px" }}
                >
                  <div className="contact-element">
                    <div className="icon">
                      {" "}
                      <i className={information.box_call}></i>
                    </div>
                    <div className="content">
                      <h3 className="title">
                        {information.box_title_call_us_today}
                      </h3>
                      <p>
                        {details.map((detail, index) => (
                          <p key={index}>
                            <a href={`mailto:${detail}`}>{detail}</a>
                          </p>
                        ))}
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-md-4">
                <div
                  className="contact-element-wrapper "
                  style={{ height: "225px" }}
                >
                  <div className="contact-element">
                    <div className="icon">
                      {" "}
                      <i className={information.box_mail}></i>
                    </div>
                    <div className="content">
                      <h3 className="title">{information.box_title_mails}</h3>

                      {emails.map((email, index) => (
                        <p key={index}>
                          <a href={`mailto:${email}`}>{email}</a>
                        </p>
                      ))}
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-md-4">
                <div className="contact-element-wrapper">
                  <div className="contact-element">
                    <div className="icon">
                      {" "}
                      <i className="fas fa-map-marker-alt"></i>
                    </div>
                    <div className="content">
                      <h3 className="title">{information.box_title_address}</h3>
                      <p>
                        <a href="https://www.google.com/maps/dir//101%2F14+SLV+Complex,+3rd+Floor,+BB+Rd,+opp.+Maruti+Suzuki+Bimal+Showroom,+Chikkaballapur,+Karnataka+562101/@13.4186801,77.6463849,12z/data=!4m8!4m7!1m0!1m5!1m1!1s0x3bb1ef23450cd7d5:0x2cd7c906893aa2c4!2m2!1d77.7287867!2d13.4186935?entry=ttu">
                          {address[0]}
                          {address[1]}
                        </a>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default ContactSection;
