import React, { useState, useEffect } from "react";
import "./Members.css";
import "../../../App.css";
import { ngrokUrl } from "../../../Utility/data";
import axios from "axios";
import Loader from "../../Loader/Loader";

function Members() {
  const [title, setTitle] = useState({});
  const [members, setMembers] = useState({});

  const [errorMembers, setErrorMembers] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false);
    });
    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    const memberValues = async () => {
      setLoading(true);
      try {
        const token = localStorage.getItem("token");
        const response = await axios.get(
          `${ngrokUrl.url}/api/readMemberTitle/1`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
              "ngrok-skip-browser-warning": "69420",
            },
          }
        );
        setTitle(response.data);
        setLoading(false);
      } catch (error) {
        console.error("Failed to fetch default values.", error);
        setErrorMembers("Failed to fetch default values.");
        setLoading(false);
      }
    };
    memberValues();
  }, []);

  useEffect(() => {
    const membersData = async () => {
      try {
        const response = await axios.get(`${ngrokUrl.url}/api/readMember`);

        setMembers(response.data);
      } catch (error) {
        console.error("Failed to fetch default values.", error);
        setError("Failed to fetch default values.");
      }
    };

    membersData();
  }, []);

  const tableData = Array.isArray(members) ? members : [];

 

  if (loading) {
    return <Loader />;
  }
  return (
    <div>
      <div class="members-section" >
        <div class="container">
          <h3 class="members-heading1">{title.title}</h3>

          <div className="row">
            {tableData.map((member) => (
              <div key={member.id} className="col-md-4">
                <div className="niva-team" style={{width:"300px", marginLeft:"10px"}}>
                  <div className="thumbnail">
                    <img
                      style={{ width: "100%", height: "350px" }}
                      className="lazy img-fluid "
                      src={member.photo_url}
                      alt="team-niva"
                    />
                  </div>
                  <div className="content">
                    <h5 className="title">{member.name}</h5>
                    <p className="position">{member.position}</p>
                  </div>
                  {/* <ul className="social-icon">
                    <li>
                      <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href={member.facebook}
                      >
                        <i className="fab fa-facebook" aria-hidden="true"></i>
                      </a>
                    </li>
                    <li>
                      <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href={member.linkedin}
                      >
                        <i className="fab fa-linkedin" aria-hidden="true"></i>
                      </a>
                    </li>
                    <li>
                      <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href={member.twitter}
                      >
                        <i className="fab fa-twitter" aria-hidden="true"></i>
                      </a>
                    </li>
                  </ul> */}
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}
export default Members;
