import React, { useState, useEffect } from "react";
import "./TopArea.css";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { ngrokUrl } from "../../../Utility/data";
import Loader from "../../Loader/Loader";

function TopArea() {
  const [aboutSettings, setAboutSettings] = useState({});
  const [errorSettings, setErrorSettings] = useState(null);
  const [loading, setLoading] = useState(true);

  const navigate = useNavigate();

  useEffect(() => {
    const fetchSettings = async () => {
      try {
        const response = await axios.get(
          `${ngrokUrl.url}/api/readAboutSetting/1`
        );
        setAboutSettings(response.data);
      } catch (error) {
        console.error("Failed to fetch default values.", error);
        setErrorSettings("Failed to fetch default values.");
      }
    };

    fetchSettings();
  }, []);

  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false);
    }, 4000);
    return () => clearTimeout(timer);
  }, []);

  return (
    <div>
      {loading ? (
        <Loader />
      ) : (
        <>
          <div className="breadcrumb-area">
            <h1 className="breadcrumb-title">About Us</h1>
            <ul className="page-list">
              <li className="item-home">
                <a
                  className="bread-link"
                  onClick={() => navigate("/")}
                  title="Home"
                >
                  Home
                </a>
              </li>
              <li className="separator separator-home"></li>
              <li className="item-current">About Us</li>
            </ul>
          </div>

          <div className="about-us">
            <div className="container">
              <div className="row align-items-center">
                <div className="col-md-5">
                  <div className="simpleParallax-video">
                    <div className="simpleParallax">
                      <div
                        className="simpleParallax"
                        style={{ overflow: "hidden" }}
                      >
                        <img
                          src={aboutSettings.photo_url}

                          className="lazy thumparallax img-fluid "
                          data-src=""
                          alt="about-image"
                          id="simpleParallax-image"
                          style={{
                            transform: "translate3d(0px, 26px, 0px) scale(1.3)",
                            transition:
                              "transform 2s cubic-bezier(0, 0, 0, 1) 0s",
                            willChange: "transform",
                            borderRadius: "12px",
                            objectFit: "cover",
                            height: "600px",
                          }}
                        />
                      </div>
                    </div>
                    <a
                      className="popup-vimeo-video"
                      href={`${aboutSettings.button_youtube_link}`}
                    >
                      <i className="far fa-play-circle"></i>
                    </a>
                  </div>
                </div>
                <div className="col-md-7">
                  <h4 className="about-heading1-home title1">
                    {aboutSettings.subtitle}
                  </h4>
                  <h3 className="about-heading2-home ">
                    {aboutSettings.title}
                  </h3>

                  <p>{aboutSettings.description}</p>

                  <a
                    href={`${aboutSettings.button_link}`}
                    className="btn btn-style1 Toparea"
                  >
                    <span style={{ color: "white" }}>
                      {aboutSettings.button_text}
                    </span>
                  </a>
                </div>
              </div>
            </div>
          </div>

        </>
      )}
    </div>
  );
}

export default TopArea;
